<template>
  <template v-if='src.size'>
    <picture :class="customClass ? customClass : ''" v-if='$device.isDesktopOrTablet'>
      <source type="image/webp"
              :srcset="
              `${src.size.size3_webp ? src.size.size3_webp : src.url}` + ' 1x, ' +
              `${src.size.size2_webp ? src.size.size2_webp : src.url}` + ' 2x'">
      <img :class="['img-cover', customImgClass ? customImgClass : '']" :alt="alt" :src="src.url"
           :srcset="
              `${src.size.size3 ? src.size.size3 : src.url}` + ' 1x, ' +
              `${src.size.size2 ? src.size.size2 : src.url}` + ' 2x'"
            @error="$emit('error')">
    </picture>
    <picture :class="customClass ? customClass : ''" v-else>
      <source type="image/webp"
              :srcset="`${src.size.size1_webp ? src.size.size1_webp : src.url}`">
      <img :class="['img-cover', customImgClass ? customImgClass : '']" :alt="alt" :src="src.url"
           :srcset="`${src.size.size1 ? src.size.size1 : src.url}`"
           @error="$emit('error')">
    </picture>
  </template>
  <template v-else>
    <img :class="['img-cover', customImgClass ? customImgClass : '', customClass ? customClass : '']" :src="src.url || src" :alt="alt" @error="$emit('error')" />
  </template>

</template>

<script>
import { useStore } from 'vuex'
import { computed } from 'vue'
import { PHONE } from '~/constant/common'

export default {
  name: 'BaseResponsiveImages',
  props: ['src', 'alt', 'customClass', 'customImgClass'],
  emits: ['error'],
  setup() {
    const store = useStore()

    const windowWidth = computed(() => store.state.generalStore.generalData.windowWidth)

    return {
      windowWidth, PHONE
    }
  }
}
</script>

<style lang='scss' scoped>
</style>
